import React, { PropsWithChildren } from 'react';
import cn from 'classnames';

import styles from './circles-background.module.scss';

export function CirclesBackground(props: PropsWithChildren<{ id?: string; className?: string; style?: React.CSSProperties; }>): JSX.Element {
    const { className, ...elseProps } = props;

    return <div
        className={cn(styles.circlesBackground, props.className)}
        {...elseProps}
    />;
}
