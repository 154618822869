import React from 'react';
import { useUnique } from 'utils/hooks';
import styles from './arrow.module.scss';

export function ArrowBig(): JSX.Element {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="30" fill="none" viewBox="0 0 42 30">
            <path fill="currentColor" d="M26.7 30l15-15-15-15L24 2.7l10.4 10.4H0v3.8h34.4L24 27.3l2.7 2.7z" />
        </svg>
    );
}

type ArrowSmallProps = {
    style?: any;
};

export function ArrowSmall(props: ArrowSmallProps): JSX.Element {
    const n = useUnique();
    const id = `marker-${n}`;

    return (
        <div data-arrow-container className={styles.arrow} style={props.style}>
            <svg data-arrow>
                <defs>
                    <marker
                        markerUnits="userSpaceOnUse"
                        id={id}
                        markerWidth="13"
                        markerHeight="21"
                        refX="11"
                        refY="10.5"
                        viewBox="0 0 13 21"
                    >
                        <path fill="currentColor" d="M12.4 10.5L1.9 21 0 19l7.2-7.2V9.1L0 2 2 0l10.4 10.5z" />
                    </marker>
                </defs>
                <line
                    x1="0"
                    y1="50%"
                    x2="100%"
                    y2="50%"
                    strokeWidth="2.71"
                    markerEnd={`url(#${id})`}
                    stroke="currentColor"
                />
            </svg>
        </div>
    );
}
