import React, { PropsWithChildren } from 'react';
import cn from 'classnames';
import { Grid } from 'components/grid/grid';

import styles from './small-citation.module.scss';

type SmallCitationProps = PropsWithChildren<{
    className?: string;
}>;

export function SmallCitation(props: SmallCitationProps): JSX.Element {
    const { className, ...elseProps } = props;

    return (
        <Grid className={cn(styles.citationGrid, className)}>
            <div className={styles.citation} {...elseProps} />
        </Grid>
    );
}

export function SmallCitationQuote(props: PropsWithChildren<{ className?: string }>) {
    const { className, ...elseProps } = props;

    return <span
        className={cn(styles.citation__quote, className)}
        {...elseProps}
    />;
}

export function SmallCitationData(props: PropsWithChildren<{ className?: string }>): JSX.Element {
    const { className, ...elseProps } = props;

    return <div
        className={cn(styles.citation__data, className)}
        {...elseProps}
    />;
}
