import React from 'react';
import cn from 'classnames';
import styles from './parallax.module.scss';

export function ParallaxBody(props: React.PropsWithChildren<{ style?: React.CSSProperties; }>): JSX.Element {
    return <section className={styles.parallaxBody} {...props} />;
}

type ParallaxImageProps = React.PropsWithChildren<{
    style?: React.CSSProperties;
    rootImage?: boolean;
}>;

export function ParallaxImage(props: ParallaxImageProps): JSX.Element {
    const { rootImage = false, ...elseProps } = props;

    return <div className={cn(styles.parallaxImage, rootImage && styles.isRoot)}
                {...elseProps}
    />;
}
