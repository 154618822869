import React from 'react';
import Img from 'gatsby-image';
import cn from 'classnames';
import { Grid } from 'components';
import styles from './feature.module.scss';


export function FeatureBody(props: React.PropsWithChildren<any>): JSX.Element {
    const { className, ...elseProps } = props;
    return (
        <Grid className={styles.featureGrid}>
            <div className={cn(styles.featureBody, className)} {...elseProps} />
        </Grid>
    );
}


type FeatureItemProps = {
    title: string;
    description: string;
    image: any;
    [key: string]: any;
};


export function FeatureItem(props: FeatureItemProps) {
    const { title, description, image, ...otherProps } = props;

    return (
        <div className={styles.featureItem} {...otherProps}>
            <Img fluid={image}
                 fadeIn={true}
                 className={styles.featureItem__img}
            />
            <h4 className={styles.featureItem__title}>{title}</h4>
            <p className={styles.featureItem__description}>{description}</p>
        </div>
    );
}
