import React from 'react';
import cn from 'classnames';
import { Grid } from 'components';
import styles from './topic.module.scss';

export function Topic(props: React.PropsWithChildren<any>): JSX.Element {
    const { className, ...elseProps } = props;

    return <Grid className={styles.topicGrid}>
        <section className={cn(styles.topic, className)} {...elseProps} />
    </Grid>;
}

export function TopicH1(props: React.PropsWithChildren<any>): JSX.Element {
    const { className, ...elseProps } = props;

    return <h1
        data-aos="fade-down"
        data-aos-offset="200"
        data-aos-delay={60}
        className={cn(styles.topic__title, styles.isH1, className)}
        {...elseProps}
    />;
}

export function TopicHeading(props: React.PropsWithChildren<any>): JSX.Element {
    const { className, tag = 'h2', ...elseProps } = props;

    const elementProps = {
        'data-aos': "fade-down",
        'data-aos-offset': "200",
        'data-aos-delay': 60 * 3,
        className: cn(styles.topic__title, className),
        ...elseProps,
    };

    return React.createElement(tag, elementProps, props.children);
}

export function TopicParagraph(props: React.PropsWithChildren<any>): JSX.Element {
    const { className, ...elseProps } = props;

    return <p
        data-aos="fade-down"
        data-aos-offset="200"
        data-aos-delay={60 * 2}
        className={cn(styles.topic__paragraph, className)}
        {...elseProps}
    />;
}