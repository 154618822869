import React, { CSSProperties, PropsWithChildren } from 'react';
import { Link } from 'gatsby';
import cn from 'classnames';
import { useUnique } from 'utils/hooks';
import { Grid } from 'components';
import styles from './cards.module.scss';

export function Cards(props: PropsWithChildren<{}>): JSX.Element {
    return <Grid className={styles.cardsGrid}>
        <div className={styles.cards} {...props} />
    </Grid>;
}

type CardProps = PropsWithChildren<{
    color?: 'light' | 'violet' | 'gray';
    className?: string;
    to?: string;
    id?: string;
    isBig?: boolean;
}>;

export function Card(props: CardProps): JSX.Element {
    const { color = 'light', to, className, isBig = false, ...restProps } = props;

    const cardClass = cn(styles.card, className, {
        [styles.isBig]: isBig,
        [styles.cardGray]: color === 'gray',
        [styles.cardLight]: color === 'light',
        [styles.cardViolet]: color === 'violet',
    });

    if (to === undefined) {
        return <div
            className={cardClass}
            {...restProps}
        />;
    }

    return <Link
        to={to}
        className={cardClass}
        {...restProps}
    />;
}

export function CardBody(props: PropsWithChildren<{ style?: CSSProperties; withImage?: boolean; }>): JSX.Element {
    return <div
        className={styles.card__body}
        {...props}
    />;
}

export function CardImage(props: PropsWithChildren<{ style?: CSSProperties }>): JSX.Element {
    return <div className={styles.card__image} {...props} />;
}

export function CardBodyImage(props: PropsWithChildren<{ style?: CSSProperties }>): JSX.Element {
    return <div className={styles.card__bodyImage} {...props} />;
}

export function CardBodyLogo(props: PropsWithChildren<{ style?: CSSProperties }>): JSX.Element {
    return <div className={styles.card__bodyLogo} {...props} />;
}

export function CardCircles(): JSX.Element {
    return <div className={styles.card__circles} />;
}

export function CardGreenArrow(): JSX.Element {
    const n = useUnique();
    const id = `marker-${n}`;

    return (
        <div data-arrow-green-container className={styles.arrow}>
            <svg data-arrow-green>
                <defs>
                    <marker
                        markerUnits="userSpaceOnUse"
                        id={id}
                        markerWidth="13"
                        markerHeight="21"
                        refX="11"
                        refY="10.5"
                        viewBox="0 0 13 21"
                    >
                        <path fill="currentColor" d="M12.4 10.5L1.9 21 0 19l7.2-7.2V9.1L0 2 2 0l10.4 10.5z" />
                    </marker>
                </defs>
                <line
                    x1="0"
                    y1="50%"
                    x2="100%"
                    y2="50%"
                    strokeWidth="2.71"
                    markerEnd={`url(#${id})`}
                    stroke="currentColor"
                />
            </svg>
        </div>
    );
}