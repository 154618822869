import React from 'react';
import cn from 'classnames';

import styles from './button.module.scss';

type ButtonProps = React.PropsWithChildren<{
    tag?: 'a' | React.FC | React.Component | any;
    className?: string;

    to?: any;
    href?: any;
    target?: string;
}>;

export function Button(props: ButtonProps): JSX.Element {
    const { tag = 'a', children, className, ...elseProps } = props;

    const componentProps = {
        className: cn(styles.button, className),
        ...elseProps,
    };

    return React.createElement(
        tag,
        componentProps,
        <div className={styles.button__content}>{children}</div>,
    );
}
